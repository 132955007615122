export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export async function throwErrorIfNotOK(response) {
    if (!response.ok) {
        const reply = await response.json()
        throw new Error(`${response.statusText}: ${(reply.message || 'Unknown error')}`)
    }
    return response
}