export default {
    business: (state) => state.business,
    key: (state) => (key) => state.business?.[key],
    address: (state) => state.business?.addresses[0],
    asa: (state) => state.business?.asa,
    subscription: (state) => {
        const base = Object.assign({}, state.business?.stripe_subscription)

        if (!base.data) {
            return base
        }

        try {
            return Object.assign(base, JSON.parse(base.data))
        } catch(e) {
            return base
        }
    },
}