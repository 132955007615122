import { throwErrorIfNotOK } from '@/utils/flowControl'

export default {
    refresh({ state, commit, rootGetters }) {
        return fetch(`/user/api/business/${state.business?.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            }
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
        .then(({ business }) => {
            commit('SET_BUSINESS', business)
            return business
        })
    },
    save({ state, rootGetters }) {
        return fetch('/user/api/business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                ...state.business
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },

    setAddress({ commit }, address) {
        commit('SET_ADDRESS', address)
    },

    saveAddress({ state, rootGetters }) {
        return fetch('/user/api/business/address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                business_id: state.business.id,
                ...state.business.addresses[0]
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },
    setState({ commit }, business) {
        commit('SET_BUSINESS', business)
    }
}