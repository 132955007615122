export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  getters: {
    config: (state) => state,
    key: (state) => (key) => state[key],
  },
  state: {
    stripe_pk: null,
    csrf_token: null,
    supabase_public_key: null,
    supabase_project_url: null,
    flags: {
      paperless: false,
    }
  },
  mutations: {
    SET_STATE (state, data) {
      Object.assign(state, data)
    }
  },
  actions: {
    setState ({ commit }, data) {
      commit('SET_STATE', data)
    }
  }
}
