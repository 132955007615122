export default {
    business: {
        id: 0,
        addresses: [{
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        }],
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
        auto_renewal: 0,
        use_business_info_for_billing: 1,
        primary_user_id: 0,
        stripe_subscription: null,
        asa: null
    }
}