export default {
  user: {},
  impersonated: {
    business: {
      asa: {
        price: {}
      }
    }
  },
  viewUser: {
    roles: []
  },
  banner: {},
  manual: null
}
