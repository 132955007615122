export default {
    SET_BUSINESS(state, business) {
        const fields = Object.keys(state.business)
        Object.assign(state.business, fields.reduce((obj, field) => {
            return Object.assign(obj, {
                [field]: business[field] ?? state.business[field] ?? null
            })
        }, {}))
    },
    SET_ADDRESS(state, address) {
        !state.business.addresses.length ? 
            state.business.addresses.push(address) :
            Object.assign(state.business.addresses[0], address)
    }
}