import { throwErrorIfNotOK } from '@/utils/flowControl'

export default {
    saveCustomer({ rootGetters }, { name }) {
        return fetch('/user/api/customer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                user_id: rootGetters['business/key']('primary_user_id'),
                name,
                email: rootGetters['user/impersonated'].email
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },
    saveCard({ rootGetters }, { token }) {
        return fetch('/payment/add-instrument', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                token,
                default_instrument: true,
                card: true,
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },
    deleteCard({ rootGetters }, { default_instrument, card }) {
      return fetch('/payment/delete-instrument', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
        },
        body: JSON.stringify({
          default_instrument: default_instrument,
          card: card,
        })
      })
      .then(throwErrorIfNotOK)
      .then(response => response.json())
    },
    charge({ rootGetters }, { token = null }) {
        return fetch('/payment/create-transaction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                instrument_type: 'credit_card',
                plan: rootGetters['user/impersonated'].business?.asa?.price?.id,
                token: token || rootGetters['user/activeCard'].id,
                default_instrument: true,
                payment_type: rootGetters['business/key']('auto_renewal') ? 'subscription' : 'one_time',
                instrument_mode: 'existing'
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },

    renewSubscription({ rootGetters }) {
        return fetch(`/user/api/business/renew`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                business_id: rootGetters['business/key']('id'),
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    },

    invoiceSubscription({ rootGetters }, overrides = {}) {
        return fetch(`/payment/invoice-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': rootGetters['config/key']('csrf_token')
            },
            body: JSON.stringify({
                plan: rootGetters['user/impersonated'].business?.asa?.price?.id,
                ...overrides
            })
        })
        .then(throwErrorIfNotOK)
        .then(response => response.json())
    }
}
