export default {
  user: (state) => state.user,
  viewUser: (state) => state.viewUser,
  impersonated: (state) => state.impersonated,
  business: (state) => state.impersonated?.business,
  asa: (state) => state.impersonated?.business?.asa,
  subscription: (state) => { 
    const base = Object.assign({}, state.impersonated?.business?.stripe_subscription)
    if (!base.data) {
      return base
    }
    try {
      return Object.assign(base, JSON.parse(base.data))
    } catch(e) {
      return base
    }
  },
  manual: (state) => state.manual,
  banner: (state) => state.banner,
  activeCard: (state) => state.impersonated.cards?.length ?
    state.impersonated.cards[ state.impersonated.cards.length - 1 ] : null
}
